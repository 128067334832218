import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import { SectionWrap, HeaderWrap } from './section.style'
import Heading from '../../../../components/ui/heading'
import Counter from '../../../../components/counter/layout-three'

const Section = ({
    counter0,
    text0,
    heading0,
    counter1,
    text1,
    counter2,
    text2,
    counter3,
    text3,
    counter4,
    text4,
    markHeading,
    headingStyle,
}) => {
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={{ span: 4, offset: 1 }}>
                        <HeaderWrap>
                            <Heading {...markHeading}>
                                <mark>{counter0}</mark> {text0}
                            </Heading>
                            {heading0 && (
                                <Heading {...headingStyle}>{heading0}</Heading>
                            )}
                        </HeaderWrap>
                    </Col>
                    <Col lg={7} md={8}>
                        <Row>
                            <Col sm={6}>
                                <Counter countTo={counter1} text={text1} />
                            </Col>
                            <Col sm={6}>
                                <Counter countTo={counter2} text={text2} />
                            </Col>
                            <Col sm={6}>
                                <Counter countTo={counter3} text={text3} />
                            </Col>
                            <Col sm={6}>
                                <Counter countTo={counter4} text={text4} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

Section.propTypes = {
    counterStyle: PropTypes.object,
}

Section.defaultProps = {
    markHeading: {
        layout: 'highlight',
    },
    headingStyle: {
        as: 'h3',
        mt: '25px',
        fontSize: '38px',
        child: {
            color: 'primary',
        },
        responsive: {
            large: {
                fontSize: '30px',
            },
            small: {
                fontSize: '24px',
            },
        },
    },
}

export default Section
